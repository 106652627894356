/* eslint-disable */
import styled from "styled-components"
import tw from "tailwind.macro"

export const Layout = styled.div`
width:100%;
height:100%
background-repeat: no-repeat;
background-size: contain 100%;
background-image: url(${require("../../images/aboutBackground.jpg")});
}
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    margin: 1rem auto;
    flex-wrap: wrap;
  }
`

export const LowerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8.75rem auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    margin: 1rem auto;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
`

export const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 4rem;
  margin: 3rem auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto;
    padding: 0;
  }
`
export const RightWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 320px) {
    margin: 1rem 0;
  }
`
export const PhotosContainerRight = styled.div`
  display: flex;
  overflow: hidden;
  position: none;
  margin: 1rem auto;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    width: calc(100% + 80px);
    position: absolute;
    z-index: 99;
    right: -100px;
    top: -50px;
  }
`

export const PhotosContainerLeft = styled.div`
  ${tw`flex flex-wrap -mx-px overflow-visible md:-mx-2`}
  width: calc(110% + 250px);
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: flex-start;

  @media only screen and (max-width: 1024px) {
    left: 50px;
  }

  @media only screen and (max-width: 768px) {
    z-index: 1;
    margin: 3rem 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @media only screen and (max-width: 751px) {
    display: none;
  }
`

export const PhotosWrapper = styled.div`
  ${tw`flex flex-wrap -mx-4 overflow-visible justify-center`};
  align-content: flex-start;
  margin: 0 -1.5rem;
  width: 50%;
  min-width: 340px;

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: auto;
  }
  @media only screen and (max-width: 751px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 320px) {
    justify-content: flex-start;
  }
`

export const PhotoWrapper = styled.div`
  ${tw`w-full my-1 px-1 w-1/2 overflow-visible`}
  object-fit: contain;
  margin: -0.8rem;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;

  @media only screen and (max-width: 768px) {
    width: 55%;
    height: auto;
  }
  @media only screen and (max-width: 320px) {
    width: 45%;
  }
`

export const NameBlock = styled.div`
  position: absolute;
  width: 75%;
  bottom: 35px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  color: white;
`

export const Title = styled.div`
  font-size: 1.9rem;
  font-weight: 600;
  width: 85%;
  margin-bottom: 1rem;
`

export const Note = styled.p`
  justify-content: center;
  align-items: center;
  margin: 3rem auto;
  color: #8c9bb3;
  line-height: 22px;
  font-size: 15px;
  font-weight: 400;
  height: 100%;

  @media only screen and (max-width: 1024px) {
    left: 50px;
  }

  @media only screen and (max-width: 768px) {
    margin: 1rem auto 0 auto;
  }
`

export const SubNote = styled(Note)`
  width: 80%;
  margin: 3rem auto auto -4rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto;
  }
`

export const FeatureImage = styled.div`
  margin: 5rem auto -10rem auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem auto -8rem auto;
  }
`

export const Br = styled.br`
  margin: 1rem auto;
`

export const MeetTheTeam = styled.p`
  display: block;
  width: 100%;
  font-size: 28px;
  font-weight: 400;
  margin: 2rem auto 2rem 4rem;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
export const MeetTheTeamMobile = styled.p`
  display: none;
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  margin: 5rem auto 3rem auto;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`

export const PhotosContainerLeftMobile = styled.div`
  display: none;
  @media only screen and (max-width: 751px) {
    display: block;
  }
`
